import domReady from '@roots/sage/client/dom-ready';
import Global from '@scripts/components/Global';
import Header from '@scripts/components/Header';


/**
 * Application entrypoint
 */
domReady(async () => {

  const __global = new Global();
  __global.init();

  const __header = new Header();
  __header.init();

});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
