import Component from '@scripts/components/Component';
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollSmoother } from "gsap/ScrollSmoother";

gsap.registerPlugin(ScrollSmoother, ScrollToPlugin);

export default class Global extends Component {
  constructor() {
    super();

  }

  init() {
    ScrollSmoother.create({
        smooth: 1.5,
        effects: true,
        smoothTouch: 0.1,
    });
  }
}