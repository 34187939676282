import Component from '@scripts/components/Component';

export default class Header extends Component {
  constructor() {
    super();
    this.Header = document.querySelector('.js-header');
  }

  init() {
    if(!this.Header) return;

  }
}